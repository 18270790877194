import { action, computed, makeObservable, reaction } from 'mobx';
import { Api } from '../../service/Api';
import { ApiRequestConfig } from '../../service/ApiRequest';
import { OrderStatus, OrderStatusResource } from '../../components/OrderStatus';
import { NoticeResource } from '../NoticeResource';

export class OrderStatuses {
  private orderStatusesRequest = Api.orderStatuses();

  constructor(private productId: number, public communicationNumber?: string) {
    makeObservable(this);
  }

  private getHasPendingOrders = () => this.hasPendingOrders;
  private togglePolling = () =>
    this.hasPendingOrders ? this.orderStatusesRequest.load(this.requestConfig) : this.orderStatusesRequest.cancel();
  private disposeReaction = reaction(this.getHasPendingOrders, this.togglePolling); // eslint-disable-line: member-ordering

  @action
  setData(data: OrderStatusResource[]): void {
    this.orderStatusesRequest.data = data;
  }

  terminate() {
    this.orderStatusesRequest.cancel();
    this.disposeReaction();
  }

  @computed
  get hasPendingOrders(): boolean {
    return !!this.pendingOrderStatuses.length;
  }

  @computed
  get isAllOrdersFinished(): boolean {
    return this.hasFinishedOrderStatuses && !this.hasPendingOrders;
  }

  @computed
  get notificationUrl(): string | undefined {
    const ordersWithNotificationUrl = this.orderStatuses.filter(({ notificationUrl }) => !!notificationUrl);
    return ordersWithNotificationUrl.length > 0 ? ordersWithNotificationUrl[0].notificationUrl : undefined;
  }

  @computed
  get orderNotices(): NoticeResource[] {
    const isSingular = this.pendingOrderStatuses.length === 1;
    const notificationUrl = this.notificationUrl;
    const options = { notificationUrl };

    return this.pendingOrderStatuses.slice(0, 1).map((orderStatus) => ({
      code: `order-status-${orderStatus.orderId}`,
      label: orderStatus.getLabelKey(isSingular),
      type: 'success',
      closable: false,
      source: orderStatus.source,
      options,
      orderType: orderStatus.orderType,
      executionDate: orderStatus.executionDate,
    }));
  }

  @computed
  get finishedOrderStatuses() {
    return this.orderStatuses.filter(({ isFinished }) => isFinished);
  }

  @computed
  private get orderStatuses(): OrderStatus[] {
    return this.orderStatusesRequest.data?.map((resource) => new OrderStatus(resource)) || [];
  }

  @computed
  private get hasFinishedOrderStatuses(): boolean {
    return !!this.finishedOrderStatuses.length;
  }

  @computed
  private get pendingOrderStatuses() {
    return this.orderStatuses.filter(({ isPending }) => isPending);
  }

  @computed
  private get requestConfig(): ApiRequestConfig {
    return {
      poll: true,
      urlParams: { productId: this.productId },
      params: {
        msisdn: this.communicationNumber,
        orderIds: this.orderStatuses.map(({ orderId }) => orderId),
      },
    };
  }
}
