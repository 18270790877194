import React, { useContext } from 'react';
import { UiBlocking } from '../../shared/components/uiBlocking/UiBlocking';
import { CustomerProductsPubSubConnectionStore } from '../../shared/store/customerProductsPubSubConnection/CustomerProductsPubSubConnectionStore';
import { Environment } from '../../shared/model/config/Environment';

export interface CustomerProductsContext {
  environment: Environment;
  uiBlocking: UiBlocking;
  pubSubConnectionStore: CustomerProductsPubSubConnectionStore;
  /**
   * @todo ProductsStore & ProductUserStore should also be here not in RootStoreContextDeprecated
   */
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerProductsContext = React.createContext<CustomerProductsContext>({} as CustomerProductsContext);
export const CustomerProductsContextProvider: React.FC<{ value: CustomerProductsContext; children?: React.ReactNode }> = (props) => (
  <CustomerProductsContext.Provider {...props} />
);
export const useCustomerProductsContext = () => useContext(CustomerProductsContext);
