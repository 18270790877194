export interface OrderStatusResource {
  orderId: number;
  status: OrderStatusValue;
  type: OrderStatusType;
  orderType?: string;
  executionDate?: string;
  serviceNames?: string[];
  notificationUrl?: string;
  source?: string;
}

export class OrderStatus {
  constructor(private readonly resource: OrderStatusResource) {}

  get notificationUrl(): string | undefined {
    return this.resource.notificationUrl;
  }

  get hasNotificationUrl(): boolean {
    return !!this.resource.notificationUrl;
  }

  get orderId(): number {
    return this.resource.orderId;
  }

  get status(): OrderStatusValue {
    return this.resource.status;
  }

  get type(): OrderStatusType {
    return this.isRoamingOrder ? OrderStatusType.ROAMING : this.resource.type;
  }

  get executionDate(): string | undefined {
    return this.resource?.executionDate;
  }

  get orderType(): string | undefined {
    return this.resource?.orderType;
  }

  get isClnmobOrderType(): boolean {
    return this.orderType === OrderType.CLNMOB;
  }

  get isPending(): boolean {
    return this.resource.status === OrderStatusValue.PROCESSING;
  }

  get isFulfilled(): boolean {
    return this.resource.status === OrderStatusValue.OK;
  }

  get isFinished(): boolean {
    return [OrderStatusValue.OK, OrderStatusValue.ERROR, OrderStatusValue.CANCELED].includes(this.resource.status);
  }

  get source(): string | undefined {
    return this.resource?.source;
  }

  private get serviceNames(): string[] {
    return this.resource.serviceNames || [];
  }

  private get isRoamingOrder(): boolean {
    return this.resource.type === OrderStatusType.CHANGE_SERVICE && this.serviceNames.some((serviceName) => serviceName === 'GRV');
  }

  getLabelKey(isSingular: boolean): string {
    const { status, type, isFinished } = this;
    const noticeType = isFinished ? 'finalized-order' : 'pending-order';
    const statusType = isSingular ? `.${type}` : '';
    const labelKey =
      this.isClnmobOrderType && !!this.executionDate
        ? 'my-services.CLNMOB.notice'
        : `my-services.${noticeType}${statusType}.${status}.notice`;

    return labelKey;
  }
}

export enum OrderStatusType {
  CHANGE_SERVICE = 'CHANGE_SERVICE',
  CHANGE_PACKAGE = 'CHANGE_PACKAGE',
  DATA_CHANGE = 'DATA_CHANGE',
  EXTRA_DATA = 'EXTRA_DATA',
  MEMBER_CHANGE = 'MEMBER_CHANGE',
  OTHER = 'OTHER',
  ROAMING = 'ROAMING',
}

export enum OrderType {
  CLNMOB = 'CLNMOB',
}

export enum OrderStatusValue {
  OK = 'OK',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  PROCESSING = 'PROCESSING',
}
